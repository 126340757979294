import React from 'react';
import { string, objectOf, bool, any } from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { getCompanyRoute } from '@/helpers/router';
import Img from '@/components/Img';
import './styles.scss';

function CompanyDigest({ className, company, redirectOnClick }) {
  const classNames = cx('CompanyDigest', className);

  function renderCompany() {
    return (
      <>
        <figure>
          <Img src={get(company, 'logo.read_url')} alt={get(company, 'name')} />
        </figure>
        <div className="CompanyDigest__inner">
          <h4>{get(company, 'name')}</h4>
          <h5>{get(company, 'job.name')}</h5>
        </div>
      </>
    );
  }

  if (redirectOnClick && get(company, 'leader.kind') === 'expert') {
    return (
      <Link
        className={classNames}
        to={{
          pathname: getCompanyRoute(
            get(company, 'id'),
            get(company, 'leader.kind')
          ),
          state: { modal: true },
        }}
      >
        {renderCompany()}
      </Link>
    );
  }

  return <div className={classNames}>{renderCompany()}</div>;
}

CompanyDigest.propTypes = {
  className: string,
  company: objectOf(any).isRequired,
  redirectOnClick: bool,
};

CompanyDigest.defaultProps = {
  className: null,
  redirectOnClick: true,
};

export default CompanyDigest;
