import React, { useEffect } from 'react';
import { objectOf, any } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import HeaderLink from '@/components/Header/Nav/Link';

function MeltingSpot({ item }) {
  const { t } = useTranslation();
  // TODO: implement deeplink logic params when feature is working in meltingSpot

  useEffect(() => {
    // Check if the script is already in the document
    const existingScript = document.querySelector(
      'script[src="https://cdn.jsdelivr.net/npm/@meltingspot/meltingspot-js"]'
    );

    if (!existingScript) {
      const cdnScript = document.createElement('script');
      cdnScript.src =
        'https://cdn.jsdelivr.net/npm/@meltingspot/meltingspot-js';
      cdnScript.crossOrigin = 'anonymous'; // Adding the crossorigin attribute

      document.head.appendChild(cdnScript);
    }
  }, []);

  return (
    <>
      <HeaderLink
        to={item.to}
        key={item.id}
        className={item.badge ? 'Header__link__text' : ''}
        target={item.target}
      >
        {t(item.label)}
      </HeaderLink>
    </>
  );
}

MeltingSpot.propTypes = {
  item: objectOf(any).isRequired,
};

export default MeltingSpot;
