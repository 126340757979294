/* Candidacy */
export const CANDIDACY_ACCEPTED = 'candidacy_accepted';
export const CANDIDACY_ARCHIVED = 'candidacy_archived';
export const CANDIDACY_ARCHIVED_MISSION_OWNER =
  'candidacy_archived_mission_owner';
export const CANDIDACY_MOVE_STEP_FOUR = 'candidacy_move_step_four';
export const CANDIDACY_MOVE_STEP_THREE = 'candidacy_move_step_three';
export const CANDIDACY_MOVE_STEP_TWO = 'candidacy_move_step_two';
export const CANDIDACY_MOVE_STEP_ONE = 'candidacy_move_step_one';
export const CANDIDACY_MOVE_UNDER_REVIEW = 'candidacy_move_under_review';

/* Channel */
export const CHANNEL_INIT_CONVERSATION = 'channel_init_conversation';
export const CHANNEL_USER_JOIN = 'channel_user_join';
export const CHANNEL_USER_LEAVE = 'channel_user_leave';

/* Company */
export const COMPANY_INVITATION = 'company_invitation';

/* Contract */
export const CONTRACT_CANCELATION_ADMIN_ACCEPT_CLIENT =
  'contract_cancelation_admin_accept_client';
export const CONTRACT_CANCELATION_ADMIN_ACCEPT_EXPERT =
  'contract_cancelation_admin_accept_expert';
export const CONTRACT_CANCELATION_ADMIN_REJECT =
  'contract_cancelation_admin_reject';
export const CONTRACT_CANCELATION_CLIENT_ACCEPT =
  'contract_cancelation_client_accept';
export const CONTRACT_CANCELATION_CLIENT_REJECT =
  'contract_cancelation_client_reject';
export const CONTRACT_CREATION_ADMIN_ACCEPT_CLIENT =
  'contract_creation_admin_accept_client';
export const CONTRACT_CREATION_ADMIN_ACCEPT_EXPERT =
  'contract_creation_admin_accept_expert';
export const CONTRACT_CREATION_ADMIN_REJECT = 'contract_creation_admin_reject';
export const CONTRACT_CREATION_CLIENT_ACCEPT =
  'contract_creation_client_accept';
export const CONTRACT_CREATION_CLIENT_REJECT =
  'contract_creation_client_reject';
export const CONTRACT_DELIVERY_ADMIN_ACCEPT_CLIENT =
  'contract_delivery_admin_accept_client';
export const CONTRACT_DELIVERY_ADMIN_ACCEPT_EXPERT =
  'contract_delivery_admin_accept_expert';
export const CONTRACT_DELIVERY_ADMIN_REJECT = 'contract_delivery_admin_reject';
export const CONTRACT_DELIVERY_CLIENT_ACCEPT =
  'contract_delivery_client_accept';
export const CONTRACT_DELIVERY_CLIENT_REJECT =
  'contract_delivery_client_reject';
export const CONTRACT_DELIVERY_IN_SEVEN_DAYS =
  'contract_delivery_in_seven_days';
export const CONTRACT_DELIVERY_IN_TWO_DAYS = 'contract_delivery_in_two_days';
export const CONTRACT_DELIVERY_TODAY = 'contract_delivery_today';
export const CONTRACT_DELIVERY_TWO_DAYS_AGO = 'contract_delivery_two_days_ago';
export const CONTRACT_DELIVERY_X_DAYS_AGO = 'contract_delivery_x_days_ago';
export const CONTRACT_MODIFICATION_ADMIN_ACCEPT_CLIENT =
  'contract_modification_admin_accept_client';
export const CONTRACT_MODIFICATION_ADMIN_ACCEPT_EXPERT =
  'contract_modification_admin_accept_expert';
export const CONTRACT_MODIFICATION_ADMIN_REJECT =
  'contract_modification_admin_reject';
export const CONTRACT_MODIFICATION_CLIENT_ACCEPT =
  'contract_modification_client_accept';
export const CONTRACT_MODIFICATION_CLIENT_REJECT =
  'contract_modification_client_reject';

/* Invitation */
export const INVITATION = 'invitation';

/* Invoice */
export const INVOICE_CREATED = 'invoice_created';
export const INVOICE_DELAYED = 'invoice_delayed';
export const INVOICE_PAYED_CLIENT = 'invoice_payed_client';
export const INVOICE_PAYED_EXPERT = 'invoice_payed_expert';

/* Matching */
export const MATCHING_MISSION = 'matching_mission';

/* Message */
export const MESSAGE_CREATE = 'message_create';
export const MESSAGE_RECOMMENDATION_CREATE = 'message_recommendation_create';

/* Mission */
export const MISSION_ARCHIVED_CLIENT = 'mission_archived_client';
export const MISSION_ARCHIVED_EXPERT = 'mission_archived_expert';
export const MISSION_CANDIDATE = 'mission_candidate';
export const MISSION_CANDIDATE_BETRAYAL = 'mission_candidate_betrayal';
export const MISSION_FINISHED = 'mission_finished';
export const MISSION_INTEREST = 'mission_interest';
export const MISSION_INVITATION_REFUSED = 'mission_invitation_refused';
export const MISSION_INVITATION = 'mission_invitation';
export const MISSION_PUBLISHED = 'mission_published';
export const MISSION_SIGN_NDA = 'mission_sign_nda';
export const MISSION_TRANSFERRED_EXPERT = 'mission_transferred_expert';
export const MISSION_TRANSFERRED_CLIENT = 'mission_transferred_client';
export const MISSION_UPDATED = 'mission_updated';
export const MISSION_INVITE_TO_CREATE_PROPOSAL =
  'mission_invite_to_create_proposal';

/* Proposal */
export const PROPOSAL_ACCEPTED = 'proposal_accepted';
export const PROPOSAL_ARCHIVED = 'proposal_archived';
export const PROPOSAL_REFUSED = 'proposal_refused';
export const PROPOSAL_REQUEST_FOR_UPDATE = 'proposal_request_for_update';
export const PROPOSAL_REVIEW_ADMIN = 'proposal_review_admin';
export const PROPOSAL_REVIEW_CLIENT_CLIENT = 'proposal_review_client_client';
export const PROPOSAL_REVIEW_CLIENT_EXPERT = 'proposal_review_client_expert';

/* Recommendation */
export const RECOMMENDATION_PLATFORM_PUBLISHED =
  'recommendation_platform_published';

/* Document */
export const DOCUMENT_CREATED = 'document_created';
export const DOCUMENT_FILE_REFUSED = 'document_file_refused';
export const DOCUMENT_FILE_ACCEPTED = 'document_file_accepted';
export const DOCUMENT_FILE_EXPIRED = 'document_file_expired';
export const DOCUMENT_FILE_ARCHIVED = 'document_file_archived';
export const DOCUMENT_ARCHIVED = 'document_archived';

/* Client pool */
export const CLIENTPOOL_EXPERT_ADDED = 'clientpool_expert_added';
