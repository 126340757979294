import React, { useContext } from 'react';
import { oneOfType, arrayOf, string, object, number, bool } from 'prop-types';
import cx from 'classnames';
import Tag from '@/components/Tag';
import TagsContext from '@/components/Tags/TagsContext';
import useTranslation from '@/hooks/useTranslation';
import config from '@/_config/config';
import { ratingLetters } from '@/constants/ratingLetters';
import { getAssetImagePath, getFolderName } from '@/helpers/assets';
import { ReactComponent as Heart } from '@/images/heart.svg';
import Tooltip from '@/components/Tooltip';
import { isTagAlreadyInFavorite } from '@/helpers/skills';
import { MODES } from '@/constants/missions';
import { SKILL } from '@/constants/tagKind';
import './styles.scss';

const TagsView = ({
  tags,
  displayed,
  className,
  itemClassName,
  idKey,
  tagKind,
  labelKey,
  prefixTranslationKey,
  translation,
  variants,
  highlight,
  withIcon,
  fieldName,
  mode,
  ...props
}) => {
  const tagsContext = useContext(TagsContext);
  const surplus = Math.max(0, tags.length - displayed);
  const { t } = useTranslation();

  const tagValues = (tag) => {
    switch (true) {
      case tag.favorite:
        return {
          tooltipTitle:
            mode === MODES.VIEW
              ? `mission.favorite.tooltip.favorite.view.title`
              : `mission.favorite.tooltip.favorite.title`,
          className: 'TagWrapper__Tag--favorite-selected',
        };
      case isTagAlreadyInFavorite(tags):
        return {
          tooltipTitle: `mission.favorite.tooltip.favorite.not_allowed.title`,
          className: 'TagWrapper__Tag--favorite-not-allowed',
        };
      default:
        return {
          tooltipTitle: `mission.favorite.tooltip.no.favorite.title`,
          className: 'TagWrapper__Tag--favorite-no-selected',
        };
    }
  };

  return (
    <ul className={cx('Tags__view', className)}>
      {tags.slice(0, displayed).map((tag, index) => (
        <Tag
          key={idKey ? tag[idKey] : tag}
          variants={
            highlight && index < tagsContext.highlightMaxValues
              ? [...variants, 'secondary']
              : variants
          }
          className={
            config.mission.skills.withFavorite ? 'Tags__withFavoriteIcon' : ''
          }
          {...props}
        >
          <span className={tagsContext.withRating && tag.rating ? 'p-r-m' : ''}>
            {withIcon && (
              <img
                src={getAssetImagePath(
                  getFolderName(fieldName),
                  labelKey ? tag[labelKey] : tag
                )}
                alt="logo"
                className="TagWrapper__Tag-label--icon"
              />
            )}
            {translation
              ? t(
                  `${prefixTranslationKey || ''}${
                    labelKey ? tag[labelKey] : tag
                  }`
                )
              : `${prefixTranslationKey || ''}${
                  labelKey ? tag[labelKey] : tag
                }`}
          </span>
          {tagsContext.withRating &&
            tag.rating && (
              <span className="Tags__ratingCount">
                {config.skills.withRatingLetters
                  ? ratingLetters[tag.rating]
                  : tag.rating}
              </span>
            )}
          {tagKind === SKILL &&
            config.mission.skills.withFavorite &&
            tag.favorite && (
              <Tooltip
                title={tagValues(tag).tooltipTitle}
                className="Tags__tooltip-favoriteIcon"
              >
                <Heart className="Tags__favoriteIcon" />
              </Tooltip>
            )}
        </Tag>
      ))}
      {!!surplus && <Tag variants={variants}>+ {surplus}</Tag>}
    </ul>
  );
};

TagsView.propTypes = {
  tags: oneOfType([arrayOf(string), arrayOf(object)]),
  displayed: number,
  className: string,
  itemClassName: string,
  labelKey: string,
  idKey: string,
  prefixTranslationKey: string,
  translation: bool,
  variants: arrayOf(string),
  highlight: bool,
  withIcon: bool,
  fieldName: string,
  tagKind: string,
  mode: string,
};

TagsView.defaultProps = {
  displayed: Infinity,
  className: null,
  itemClassName: null,
  tags: [],
  labelKey: null,
  idKey: null,
  prefixTranslationKey: null,
  translation: false,
  variants: [],
  highlight: false,
  withIcon: false,
  fieldName: null,
  tagKind: null,
  mode: null,
};

export default TagsView;
