import { get, map, first, isEmpty } from 'lodash';
import { getStoredUser, getCurrentUser } from '@/api/User/queries';
import { createMission, updateMission } from '@/api/Mission/mutations';
import {
  updateCompanyLogo,
  updateAbout,
  updateInformations,
  updateInterviewsConduct,
  updateJoinUs,
  updateTools,
} from '@/api/Company/mutations';
import { formatTags } from '@/components/Tags/helpers/handler';
import client from '@/helpers/apollo';
import { formatMission } from '@/selectors/missionsCreator';
import { MODES } from '@/constants/missions';
import config from '@/_config';

const formatValues = (
  { location_place, attachments, nda, nda_attachment, ...values },
  mode
) => {
  const isMissionNda = config.mission.showNdaSection ? nda : false;
  const consultationsAttributes = get(values, 'consultations_attributes', [])
    .map(
      (consultationAttribute) =>
        get(consultationAttribute, 'consultation_lists_attributes')
          ? consultationAttribute
          : {
              ...consultationAttribute,
              consultation_lists_attributes: [],
            }
    )
    .filter((consultationAttribute) => get(consultationAttribute, 'pool'));
  const subCategories = get(values, 'sub_categories');
  return {
    ...values,
    location_place: get(location_place, 'address'),
    attachment_ids: map(attachments, 'id'),
    nda_attachment_id: isMissionNda ? get(first(nda_attachment), 'id') : null,
    ...(!isEmpty(consultationsAttributes) && {
      consultations_attributes: consultationsAttributes.map(
        (consultationAttribute) => ({
          ...{
            ...(mode === MODES.EDIT && { id: consultationAttribute.id }),
            end_date: consultationAttribute.end_date,
            pool: consultationAttribute.pool,
            ...(get(consultationAttribute, '_destroy') && {
              _destroy: get(consultationAttribute, '_destroy'),
            }),
          },
          mission_id: values.id,
          ...{
            // eslint-disable-next-line max-len
            consultation_lists_attributes: consultationAttribute.consultation_lists_attributes.map(
              (value) => ({ list_id: value })
            ),
          },
        })
      ),
    }),
    sub_categories: subCategories
      ? subCategories.map((subCategory) => ({
          id: subCategory.id,
          favorite: subCategory.favorite,
        }))
      : null,
  };
};

const handleCompanyStep = (currentCompany, updates) => {
  const updateCurrentCompany = (mutation, values) =>
    client.mutate({
      mutation,
      variables: {
        entity_id: currentCompany.id,
        ...values,
      },
    });

  const mutateLogo = !currentCompany.logo;
  const mutateSectors = !currentCompany.sectors.length;

  const mutateAbout = !currentCompany.about;
  const mutateInterviewsConduct = !currentCompany.interviews_conduct;
  const mutateJoinUs = !currentCompany.join_us;

  const companyValuesSkills = map(
    updates.profile_company_value_skills,
    (skill) => ({ name: skill.name })
  );

  return updateCurrentCompany(updateInformations, {
    kind: updates.profile_kind,
    name: updates.profile_name,
    location_place: get(updates.profile_location_place, 'address'),
    job_name: updates.profile_job_name,
    size: updates.profile_size,
    practice_culture: updates.practice_culture,
    company_value_skills: companyValuesSkills,
    rewards: updates.profile_rewards,
    ...(mutateSectors && { sectors: updates.profile_sectors }),
    ...(config.settings.barRegistrationSection && {
      siret: get(updates, 'siret'),
      bar_city: get(updates, 'bar_city'),
      bar_certificate_id: get(updates, 'bar_certificate[0].id'),
    }),
  }).then(() =>
    Promise.all([
      mutateLogo &&
        updateCurrentCompany(updateCompanyLogo, {
          ...updates.profile_picture,
          original_logo_id: updates.profile_original_logo_id,
        }),

      mutateAbout &&
        updateCurrentCompany(updateAbout, {
          about: updates.profile_about,
        }),

      mutateInterviewsConduct &&
        updateCurrentCompany(updateInterviewsConduct, {
          interviews_conduct: updates.profile_interviews_conduct,
        }),

      mutateJoinUs &&
        updateCurrentCompany(updateJoinUs, {
          join_us: updates.profile_join_us,
        }),

      !isEmpty(updates.company_tools) &&
        updateCurrentCompany(updateTools, {
          tools: formatTags(updates.company_tools),
        }),
    ])
  );
};

export default {
  Mutation: {
    createOrEditMissionAndUpdateCompany: async (
      __,
      { values, mode, missionId },
      { cache }
    ) => {
      const { currentUser } = cache.readQuery({ query: getStoredUser });

      if (!currentUser.company.is_filled && config.mission.showCompanySection) {
        await handleCompanyStep(currentUser.company, values);
        const {
          data: { current_user },
        } = await client.query({
          query: getCurrentUser,
        });
        await cache.writeData({ data: { currentUser: current_user } });
      }

      const format = formatValues(values, mode);
      const formatMissionValues = formatMission(format);

      if (mode === MODES.CREATE) {
        return client.mutate({
          mutation: createMission,
          variables: formatMissionValues,
        });
      }

      return client.mutate({
        mutation: updateMission,
        variables: { id: missionId, ...formatMissionValues },
      });
    },
  },
};
