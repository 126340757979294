import { lazy } from 'react';
import { reduce, get, isFunction } from 'lodash';
import * as routeConstants from '@/constants/router';
import config from '@/_config';
import { CLIENT } from '@/constants/userKind';

const Login = lazy(() =>
  import(/* webpackChunkName: "auth" */ '@/scenes/Login')
);

const ConfirmEmail = lazy(() =>
  import(/* webpackChunkName: "auth" */ '@/scenes/ConfirmEmail')
);

const ResendEmailConfirmation = lazy(() =>
  import(/* webpackChunkName: "auth" */ '@/scenes/ResendEmailConfirmation')
);

const ForgotPassword = lazy(() =>
  import(/* webpackChunkName: "auth" */ '@/scenes/ForgotPassword')
);

const ResetPassword = lazy(() =>
  import(/* webpackChunkName: "auth" */ '@/scenes/ResetPassword')
);

const Logout = lazy(() =>
  import(/* webpackChunkName: "auth" */ '@/scenes/Logout')
);

const Signup = lazy(() =>
  import(/* webpackChunkName: "signup" */ '@/scenes/Signup')
);

const AcceptInvitation = lazy(() =>
  import(/* webpackChunkName: "accept-invitation" */ '@/scenes/AcceptInvitation')
);

const PostSignup = lazy(() =>
  import(/* webpackChunkName: "post-signup" */ '@/scenes/PostSignup')
);

const PostSignupCvParsing = lazy(() =>
  import(/* webpackChunkName: "post-signup-linkedin" */ '@/scenes/PostSignupCvParsing')
);

const Matches = lazy(() =>
  import(/* webpackChunkName: "matches" */ '@/scenes/Matches')
);

const Missions = lazy(() =>
  import(/* webpackChunkName: "missions" */ '@/scenes/Missions')
);

const Mission = lazy(() =>
  import(/* webpackChunkName: "mission" */ '@/scenes/Mission')
);

const MissionOffline = lazy(() =>
  import(/* webpackChunkName: "mission" */ '@/scenes/MissionOffline')
);

const MissionsCreator = lazy(() =>
  import(/* webpackChunkName: "missionsCreator" */ '@/scenes/MissionsCreator')
);

const ProposalCreator = lazy(() =>
  import(/* webpackChunkName: "proposalCreator" */ '@/scenes/ProposalCreator')
);

const Proposals = lazy(() =>
  import(/* webpackChunkName: "proposals" */ '@/scenes/Proposals')
);

const Proposal = lazy(() =>
  import(/* webpackChunkName: "proposals" */ '@/scenes/Proposal')
);

const Contracts = lazy(() =>
  import(/* webpackChunkName: "contracts" */ '@/scenes/Contracts')
);

const Invoices = lazy(() =>
  import(/* webpackChunkName: "contracts" */ '@/scenes/Invoices')
);

const Contract = lazy(() =>
  import(/* webpackChunkName: "contract" */ '@/scenes/Contract')
);

const Experts = lazy(() =>
  import(/* webpackChunkName: "experts" */ '@/scenes/Experts')
);

const Expert = lazy(() =>
  import(/* webpackChunkName: "experts" */ '@/scenes/Expert')
);

const ClientPool = lazy(() =>
  import(/* webpackChunkName: "experts" */ '@/scenes/ClientPool')
);

const ClientPools = lazy(() =>
  import(/* webpackChunkName: "experts" */ '@/scenes/ClientPools')
);

const Clients = lazy(() =>
  import(/* webpackChunkName: "clients" */ '@/scenes/Clients')
);

const Client = lazy(() =>
  import(/* webpackChunkName: "clients" */ '@/scenes/Client')
);

const Profile = lazy(() =>
  import(/* webpackChunkName: "profile" */ '@/scenes/Profile')
);

const MyMissions = lazy(() =>
  import(/* webpackChunkName: "myMissions" */ '@/scenes/MyMissions')
);

const MyMissionBoard = lazy(() =>
  import(/* webpackChunkName: "myMissions" */ '@/scenes/MyMissionBoard')
);

const MyMissionProposals = lazy(() =>
  import(/* webpackChunkName: "myMissions" */ '@/scenes/MyMissionProposals')
);

const MyMissionMatches = lazy(() =>
  import(/* webpackChunkName: "myMissions" */ '@/scenes/MyMissionMatches')
);

const MyMissionInvites = lazy(() =>
  import(/* webpackChunkName: "myMissions" */ '@/scenes/MyMissionInvites')
);

const MyMissionEditor = lazy(() =>
  import(/* webpackChunkName: "missionsCreator" */ '@/scenes/MyMissionEditor')
);

const NotFound = lazy(() =>
  import(/* webpackChunkName: "notFound" */ '@/scenes/NotFound')
);

const Home = lazy(() => import(/* webpackChunkName: "home" */ '@/scenes/Home'));

const MyApplications = lazy(() =>
  import(/* webpackChunkName: "applications" */ '@/scenes/MyApplications')
);

const Conversations = lazy(() =>
  import(/* webpackChunkName: "conversations" */ '@/scenes/Conversations')
);

const Bookmarks = lazy(() =>
  import(/* webpackChunkName: "bookmarks" */ '@/scenes/Bookmarks')
);

const Bookmark = lazy(() =>
  import(/* webpackChunkName: "bookmark" */ '@/scenes/Bookmark')
);

const Settings = lazy(() =>
  import(/* webpackChunkName: "settings" */ '@/scenes/Settings')
);

const Notifications = lazy(() =>
  import(/* webpackChunkName: "notifications" */ '@/scenes/Notifications')
);

const Nda = lazy(() =>
  import(/* webpackChunkName: "notifications" */ '@/scenes/Nda')
);

const MyMissionNda = lazy(() =>
  import(/* webpackChunkName: "myMissions" */ '@/scenes/MyMissionNda')
);

const Help = lazy(() => import(/* webpackChunkName: "help" */ '@/scenes/Help'));

const HelpOffline = lazy(() =>
  import(/* webpackChunkName: "help" */ '@/scenes/HelpOffline')
);

const Community = lazy(() =>
  import(/* webpackChunkName: "myMissions" */ '@/scenes/Community')
);

const routes = {
  home: {
    path: routeConstants.HOME,
    component: Home,
    header: true,
  },
  signup: {
    path: routeConstants.SIGNUP,
    component: Signup,
    authenticated: false,
    header: false,
  },
  acceptInvitation: {
    path: routeConstants.ACCEPT_INVITATION,
    component: AcceptInvitation,
    authenticated: false,
    header: false,
  },
  login: {
    path: routeConstants.LOGIN,
    component: Login,
    header: false,
  },
  ...(config.routes.confirmEmail.enable && {
    confirmEmail: {
      path: routeConstants.CONFIRM_EMAIL,
      component: ConfirmEmail,
      header: false,
    },
  }),
  ...(config.routes.resendEmailConfirmation.enable && {
    resendEmailConfirmation: {
      path: routeConstants.RESEND_EMAIL_CONFIRMATION,
      component: ResendEmailConfirmation,
      header: false,
    },
  }),
  forgotPassword: {
    path: routeConstants.FORGOT_PASSWORD,
    component: ForgotPassword,
    header: false,
  },
  resetPassword: {
    path: routeConstants.RESET_PASSWORD,
    component: ResetPassword,
    header: false,
  },
  logout: {
    path: routeConstants.LOGOUT,
    component: Logout,
    header: false,
  },
  postSignup: {
    path: routeConstants.POST_SIGNUP,
    component: PostSignup,
    authenticated: true,
    header: false,
  },
  postSignupCvParsing: {
    path: routeConstants.POST_SIGNUP_CV_PARSING,
    component: PostSignupCvParsing,
    authenticated: true,
    header: false,
  },
  missionCreator: {
    path: routeConstants.NEW_MISSION,
    component: MissionsCreator,
    authenticated: true,
    restricted: 'client',
    header: true,
  },
  missions: {
    path: routeConstants.MISSIONS,
    component: Missions,
    restricted: 'expert',
    authenticated: get(config.routes.missions, 'authenticated', true),
    header: true,
    footer: true,
    searchAccess: true,
  },
  mission: {
    path: routeConstants.MISSION,
    component: Mission,
    offlineComponent: MissionOffline,
    authenticated: get(config.routes.mission, 'authenticated', true),
    header: true,
    modal: true,
  },
  matches: {
    path: routeConstants.MISSIONS_MATCHING,
    component: Matches,
    authenticated: true,
    restricted: 'expert',
    header: true,
  },
  proposalCreator: {
    path: routeConstants.NEW_PROPOSAL,
    component: ProposalCreator,
    authenticated: true,
    restricted: 'expert',
    header: true,
  },
  proposals: {
    path: routeConstants.PROPOSALS,
    component: Proposals,
    authenticated: true,
    header: true,
  },
  proposal: {
    path: routeConstants.PROPOSAL,
    component: Proposal,
    authenticated: true,
    header: true,
  },
  contracts: {
    path: routeConstants.CONTRACTS,
    component: Contracts,
    authenticated: true,
    header: true,
  },
  invoices: {
    path: routeConstants.INVOICES,
    component: Invoices,
    authenticated: true,
    header: true,
  },
  contract: {
    path: routeConstants.CONTRACT,
    component: Contract,
    authenticated: true,
    header: true,
  },
  experts: {
    path: routeConstants.EXPERTS,
    component: Experts,
    restricted: 'client',
    header: true,
    footer: true,
    searchAccess: true,
  },
  expert: {
    path: routeConstants.EXPERT,
    component: Expert,
    authenticated: true,
    restricted: 'client',
    header: true,
    modal: true,
  },
  clients: {
    path: routeConstants.CLIENTS,
    component: Clients,
    authenticated: true,
    header: true,
  },
  client: {
    path: routeConstants.CLIENT,
    component: Client,
    authenticated: true,
    restricted: 'expert',
    header: true,
    modal: true,
  },
  profile: {
    path: routeConstants.PROFILE,
    component: Profile,
    authenticated: true,
    header: true,
  },
  bookmarks: {
    path: routeConstants.BOOKMARKS,
    component: Bookmarks,
    authenticated: true,
    header: true,
  },
  bookmark: {
    path: routeConstants.BOOKMARK,
    component: Bookmark,
    authenticated: true,
    header: true,
  },
  clientPool: {
    path: routeConstants.CLIENT_POOL,
    component: ClientPool,
    authenticated: true,
    header: true,
  },
  clientPools: {
    path: routeConstants.CLIENT_POOLS,
    component: ClientPools,
    authenticated: true,
    header: true,
  },
  settings: {
    path: routeConstants.SETTINGS,
    component: Settings,
    authenticated: true,
    header: true,
  },
  help: {
    path: routeConstants.HELP,
    component: Help,
    offlineComponent: HelpOffline,
    authenticated: false,
    header: true,
  },
  myMissions: {
    path: routeConstants.MY_MISSIONS,
    component: MyMissions,
    authenticated: true,
    restricted: 'client',
    header: true,
  },
  myMissionBoard: {
    path: routeConstants.MY_MISSION_BOARD,
    component: MyMissionBoard,
    authenticated: true,
    restricted: 'client',
    header: true,
  },
  myMissionProposals: {
    path: routeConstants.MY_MISSION_PROPOSALS,
    component: MyMissionProposals,
    authenticated: true,
    restricted: 'client',
    header: true,
  },
  myMissionMatches: {
    path: routeConstants.MY_MISSION_MATCHES,
    component: MyMissionMatches,
    authenticated: true,
    restricted: 'client',
    header: true,
  },
  myMissionInvites: {
    path: routeConstants.MY_MISSION_INVITES,
    component: MyMissionInvites,
    authenticated: true,
    restricted: 'client',
    header: true,
  },
  myMissionEditor: {
    path: routeConstants.MY_MISSION_EDIT,
    component: MyMissionEditor,
    authenticated: true,
    restricted: 'client',
    header: true,
  },
  myApplications: {
    path: routeConstants.MY_APPLICATIONS,
    component: MyApplications,
    authenticated: true,
    restricted: 'expert',
    header: true,
  },
  conversations: {
    path: routeConstants.CONVERSATIONS,
    exact: false,
    component: Conversations,
    authenticated: true,
    header: true,
  },
  notifications: {
    path: routeConstants.NOTIFICATIONS,
    exact: true,
    requireAuth: true,
    component: Notifications,
    header: true,
  },
  nda: {
    path: routeConstants.NDA,
    component: Nda,
    authenticated: true,
    header: true,
  },
  myMissionNda: {
    path: routeConstants.MY_MISSION_NDA,
    component: MyMissionNda,
    authenticated: true,
    restricted: CLIENT,
    header: true,
  },
  community: {
    path: routeConstants.COMMUNITY,
    component: Community,
    authenticated: true,
    header: true,
  },
};

// Pour respecter l'ordre
export default {
  ...reduce(
    routes,
    (finalRoutes, route, routeKey) => ({
      ...finalRoutes,
      [routeKey]: {
        exact: true,
        ...route,
        ...reduce(
          get(config, `routes.${routeKey}`),
          (conf, attr, attrKey) => ({
            ...conf,
            [attrKey]: isFunction(attr) ? attr() : attr,
          }),
          {}
        ),
      },
    }),
    {}
  ),
  notFound: {
    component: NotFound,
    header: false,
  },
};
