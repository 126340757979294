import React, { useCallback } from 'react';
import moment from 'moment';
import { isNull, first, get, head } from 'lodash';
import { Link } from 'react-router-dom';
import { func, string } from 'prop-types';
import cx from 'classnames';
import Img from '@/components/Img';
import {
  getMyApplicationsRoute,
  getConversationRoute,
  getMissionRoute,
  getMyMissionBoardRoute,
  getMyMissionNdaRoute,
  getBookmarksRoute,
  getProfileRoute,
  getContractRoute,
  getProposalRoute,
  getMyMissionsRoute,
  getMyMissionProposalsRoute,
  getInvoicesRoute,
  getSettingsRoute,
  getMissionsMatchingRoute,
  getNewProposalRoute,
  getClientPoolsRoute,
} from '@/helpers/router';
import './styles.scss';
import { PROPOSAL } from '@/constants/entities';
import {
  CANDIDACY_ACCEPTED,
  CANDIDACY_ARCHIVED,
  CANDIDACY_ARCHIVED_MISSION_OWNER,
  CANDIDACY_MOVE_UNDER_REVIEW,
  CANDIDACY_MOVE_STEP_FOUR,
  CANDIDACY_MOVE_STEP_THREE,
  CANDIDACY_MOVE_STEP_TWO,
  CANDIDACY_MOVE_STEP_ONE,
  CHANNEL_INIT_CONVERSATION,
  CHANNEL_USER_JOIN,
  CHANNEL_USER_LEAVE,
  INVITATION,
  MATCHING_MISSION,
  MESSAGE_CREATE,
  MESSAGE_RECOMMENDATION_CREATE,
  MISSION_ARCHIVED_CLIENT,
  MISSION_ARCHIVED_EXPERT,
  MISSION_CANDIDATE,
  MISSION_CANDIDATE_BETRAYAL,
  MISSION_FINISHED,
  MISSION_INTEREST,
  MISSION_INVITATION,
  MISSION_INVITATION_REFUSED,
  MISSION_PUBLISHED,
  MISSION_SIGN_NDA,
  MISSION_TRANSFERRED_EXPERT,
  MISSION_TRANSFERRED_CLIENT,
  MISSION_UPDATED,
  PROPOSAL_ACCEPTED,
  PROPOSAL_ARCHIVED,
  PROPOSAL_REFUSED,
  PROPOSAL_REQUEST_FOR_UPDATE,
  PROPOSAL_REVIEW_ADMIN,
  PROPOSAL_REVIEW_CLIENT_CLIENT,
  PROPOSAL_REVIEW_CLIENT_EXPERT,
  RECOMMENDATION_PLATFORM_PUBLISHED,
  CONTRACT_CREATION_ADMIN_ACCEPT_CLIENT,
  CONTRACT_CREATION_ADMIN_ACCEPT_EXPERT,
  CONTRACT_CREATION_ADMIN_REJECT,
  CONTRACT_CREATION_CLIENT_ACCEPT,
  CONTRACT_CREATION_CLIENT_REJECT,
  CONTRACT_CANCELATION_ADMIN_ACCEPT_CLIENT,
  CONTRACT_CANCELATION_ADMIN_ACCEPT_EXPERT,
  CONTRACT_CANCELATION_ADMIN_REJECT,
  CONTRACT_CANCELATION_CLIENT_ACCEPT,
  CONTRACT_CANCELATION_CLIENT_REJECT,
  CONTRACT_MODIFICATION_ADMIN_ACCEPT_CLIENT,
  CONTRACT_MODIFICATION_ADMIN_ACCEPT_EXPERT,
  CONTRACT_MODIFICATION_ADMIN_REJECT,
  CONTRACT_MODIFICATION_CLIENT_ACCEPT,
  CONTRACT_MODIFICATION_CLIENT_REJECT,
  CONTRACT_DELIVERY_ADMIN_ACCEPT_CLIENT,
  CONTRACT_DELIVERY_ADMIN_ACCEPT_EXPERT,
  CONTRACT_DELIVERY_ADMIN_REJECT,
  CONTRACT_DELIVERY_CLIENT_ACCEPT,
  CONTRACT_DELIVERY_CLIENT_REJECT,
  CONTRACT_DELIVERY_IN_SEVEN_DAYS,
  CONTRACT_DELIVERY_IN_TWO_DAYS,
  CONTRACT_DELIVERY_TODAY,
  CONTRACT_DELIVERY_TWO_DAYS_AGO,
  CONTRACT_DELIVERY_X_DAYS_AGO,
  INVOICE_CREATED,
  INVOICE_DELAYED,
  INVOICE_PAYED_CLIENT,
  INVOICE_PAYED_EXPERT,
  DOCUMENT_ARCHIVED,
  DOCUMENT_CREATED,
  DOCUMENT_FILE_REFUSED,
  DOCUMENT_FILE_ACCEPTED,
  DOCUMENT_FILE_EXPIRED,
  DOCUMENT_FILE_ARCHIVED,
  MISSION_INVITE_TO_CREATE_PROPOSAL,
  CLIENTPOOL_EXPERT_ADDED,
} from '@/constants/notificationKeys';
import Proposal from '@/models/Proposal';
import { getMonthLabel } from '@/helpers/milestone';
import { REVIEW } from '@/constants/missionStatuses';
import config from '@/_config';

function getNotificationAvatar() {
  // specs
  return {
    src: null,
    alt: '',
  };
}

// Creating an instance of Proposal according to the notification
function getProposal(entity) {
  const type = get(entity, '__typename');
  if (type === PROPOSAL) {
    return new Proposal(entity);
  }
  return new Proposal(get(entity, 'proposal'));
}

function getNotificationText({ text, entity: notificationEntity }) {
  const ownerMissionFirstName = get(
    notificationEntity,
    'mission.company.leader.first_name'
  );
  const ownerMissionLastName = get(
    notificationEntity,
    'mission.company.leader.last_name'
  );
  const MissionName = get(notificationEntity, 'mission.job.name');
  const Name = get(notificationEntity, 'job.name');
  const Context = get(notificationEntity, 'context');
  const MissionContext = get(notificationEntity, 'mission.context');
  const invitations = first(get(notificationEntity, 'invitations'));
  const expertName = get(invitations, 'recipient.name');
  const userCandidacy = head(get(notificationEntity, 'candidacy.users', {}));
  const ownerProposalName = get(userCandidacy, 'company.name');
  const ownerCandidacyName = get(notificationEntity, 'name');

  const entityMissionName = config.notifications.displayMissionName
    ? MissionName
    : MissionContext;
  const entityName = config.notifications.displayMissionName ? Name : Context;
  const entityContext = config.notifications.displayMissionName
    ? Name
    : Context;
  const entityMissionContext = config.notifications.displayMissionName
    ? MissionName
    : MissionContext;

  let opts = {};
  switch (text) {
    case CANDIDACY_ARCHIVED:
      opts = {
        mission: entityMissionName,
      };
      break;
    case CANDIDACY_ARCHIVED_MISSION_OWNER:
      opts = {
        mission: entityMissionName,
        expert_name: ownerCandidacyName,
      };
      break;
    case PROPOSAL_ACCEPTED:
    case PROPOSAL_REFUSED:
    case PROPOSAL_REQUEST_FOR_UPDATE:
    case PROPOSAL_REVIEW_ADMIN:
    case PROPOSAL_REVIEW_CLIENT_CLIENT:
    case PROPOSAL_REVIEW_CLIENT_EXPERT:
      opts = {
        mission: entityMissionName || entityMissionContext,
      };
      break;
    case MESSAGE_CREATE:
      opts = {
        user: `${get(notificationEntity, 'user.first_name')} ${get(
          notificationEntity,
          'user.last_name'
        )}`,
      };
      break;
    case CONTRACT_CREATION_ADMIN_ACCEPT_EXPERT:
    case CONTRACT_CREATION_ADMIN_ACCEPT_CLIENT:
    case CONTRACT_CREATION_ADMIN_REJECT:
    case CONTRACT_CREATION_CLIENT_ACCEPT:
    case CONTRACT_CREATION_CLIENT_REJECT:
    case CONTRACT_CANCELATION_ADMIN_ACCEPT_EXPERT:
    case CONTRACT_CANCELATION_ADMIN_ACCEPT_CLIENT:
    case CONTRACT_CANCELATION_ADMIN_REJECT:
    case CONTRACT_CANCELATION_CLIENT_ACCEPT:
    case CONTRACT_CANCELATION_CLIENT_REJECT:
    case CONTRACT_MODIFICATION_ADMIN_ACCEPT_EXPERT:
    case CONTRACT_MODIFICATION_ADMIN_ACCEPT_CLIENT:
    case CONTRACT_MODIFICATION_ADMIN_REJECT:
    case CONTRACT_MODIFICATION_CLIENT_ACCEPT:
    case CONTRACT_MODIFICATION_CLIENT_REJECT:
    case CONTRACT_DELIVERY_ADMIN_ACCEPT_EXPERT:
    case CONTRACT_DELIVERY_ADMIN_ACCEPT_CLIENT:
    case CONTRACT_DELIVERY_ADMIN_REJECT:
    case CONTRACT_DELIVERY_CLIENT_ACCEPT:
    case CONTRACT_DELIVERY_CLIENT_REJECT:
    case CONTRACT_DELIVERY_IN_SEVEN_DAYS:
    case CONTRACT_DELIVERY_IN_TWO_DAYS:
    case CONTRACT_DELIVERY_TODAY:
    case CONTRACT_DELIVERY_TWO_DAYS_AGO:
      opts = {
        mission: get(notificationEntity, 'candidacy.mission.context'),
      };
      break;
    case CONTRACT_DELIVERY_X_DAYS_AGO:
      opts = {
        mission: get(notificationEntity, 'candidacy.mission.context'),
        date: moment(get(notificationEntity, 'end_date')).format('L'),
      };
      break;
    case MATCHING_MISSION:
    case MISSION_ARCHIVED_CLIENT:
      opts = {
        mission: entityName || entityContext,
      };
      break;
    case MISSION_ARCHIVED_EXPERT:
      opts = {
        mission: entityName || entityContext,
      };
      break;
    case MISSION_UPDATED:
    case MISSION_SIGN_NDA:
    case MISSION_PUBLISHED:
    case MISSION_INVITATION:
    case MISSION_TRANSFERRED_CLIENT:
      opts = {
        mission: entityName || entityContext,
      };
      break;
    case MISSION_TRANSFERRED_EXPERT:
      opts = {
        mission: entityName || entityContext,
        client: get(notificationEntity, 'company.name'),
      };
      break;
    case MISSION_CANDIDATE_BETRAYAL:
      opts = {
        client: `${get(notificationEntity, 'company.leader.first_name')} ${get(
          notificationEntity,
          'company.leader.last_name'
        )}`,
        // consultant: `${entity.owner.first_name} ${entity.owner.last_name}`,
      };
      break;
    case CANDIDACY_ACCEPTED:
    case CANDIDACY_MOVE_STEP_FOUR:
    case CANDIDACY_MOVE_STEP_THREE:
    case CANDIDACY_MOVE_STEP_TWO:
    case CANDIDACY_MOVE_STEP_ONE:
      opts = {
        client: `${ownerMissionFirstName} ${ownerMissionLastName}`,
        mission: entityMissionName || entityMissionContext,
      };
      break;
    case CANDIDACY_MOVE_UNDER_REVIEW:
      opts = {
        admin: config.name,
        mission: entityMissionName || entityMissionContext,
      };
      break;
    case CHANNEL_INIT_CONVERSATION:
    case CHANNEL_USER_JOIN:
    case CHANNEL_USER_LEAVE:
    case INVITATION:
    case MESSAGE_RECOMMENDATION_CREATE:
    case MISSION_CANDIDATE:
      opts = {
        mission: entityMissionName || entityMissionContext,
      };
      break;
    case MISSION_INVITE_TO_CREATE_PROPOSAL:
      opts = {
        mission: get(notificationEntity, 'context'),
        user: `${get(notificationEntity, 'company.leader.first_name')} ${get(
          notificationEntity,
          'company.leader.last_name'
        )}`,
      };
      break;
    case MISSION_INVITATION_REFUSED:
      opts = {
        mission: entityName,
        expert_name: expertName,
      };
      break;
    case PROPOSAL_ARCHIVED:
      opts = {
        mission: entityMissionName || entityMissionContext,
        expert: ownerProposalName,
      };
      break;
    case MISSION_FINISHED:
    case MISSION_INTEREST:
      break;
    case INVOICE_CREATED:
    case INVOICE_DELAYED:
    case INVOICE_PAYED_CLIENT:
    case INVOICE_PAYED_EXPERT:
      opts = {
        mission_name: get(notificationEntity, 'mission.context'),
        milestone_title: getMonthLabel(
          get(notificationEntity, 'contract_milestone.title')
        ),
      };
      break;
    case DOCUMENT_CREATED:
    case DOCUMENT_ARCHIVED:
      opts = {
        document: get(notificationEntity, 'name'),
      };
      break;
    case DOCUMENT_FILE_ACCEPTED:
    case DOCUMENT_FILE_EXPIRED:
    case DOCUMENT_FILE_ARCHIVED:
      opts = {
        document: get(notificationEntity, 'document.name'),
      };
      break;
    case DOCUMENT_FILE_REFUSED:
      opts = {
        document: get(notificationEntity, 'document.name'),
        reason: get(notificationEntity, 'refused_reason'),
      };
      break;
    case CLIENTPOOL_EXPERT_ADDED:
      opts = {
        client_name: get(notificationEntity, 'name'),
      };
      break;
    default:
      break;
  }

  return [`notifications.${text}.text`, opts];
}

function getProposalNotificationLink(proposal) {
  if (proposal.isReviewAdmin() || proposal.isArchived()) {
    return getMyMissionProposalsRoute(proposal.get('mission.id'));
  }
  return getProposalRoute(proposal.get('id'));
}

function getInvitationNotificationLink({ notificationEntity, currentUser }) {
  const accessInvitations = get(currentUser, 'company.invitations_access');
  if (accessInvitations) {
    return getMissionRoute(get(notificationEntity, 'id'));
  }
  return getMyApplicationsRoute('invitations');
}

function getMatchingNotificationLink({ notificationEntity, currentUser }) {
  const accessMatching = get(currentUser, 'company.mission_matching_access');
  if (accessMatching) {
    return getMissionRoute(get(notificationEntity, 'id'));
  }
  return getMissionsMatchingRoute();
}

function getNotificationLink({
  text,
  entity: notificationEntity,
  currentUser,
}) {
  // specs
  const proposal = getProposal(notificationEntity);
  switch (text) {
    case CANDIDACY_ACCEPTED:
    case CANDIDACY_MOVE_STEP_FOUR:
    case CANDIDACY_MOVE_STEP_THREE:
    case CANDIDACY_MOVE_STEP_TWO:
    case CANDIDACY_MOVE_STEP_ONE:
    case CANDIDACY_ARCHIVED:
    case CANDIDACY_MOVE_UNDER_REVIEW:
      // Candidacy can be archived when mission status is changed from published to review
      // than the mission cannot be viewed
      return get(notificationEntity, 'mission.status') === REVIEW
        ? getMyApplicationsRoute()
        : getMissionRoute(get(notificationEntity, 'mission.id'));
    case CANDIDACY_ARCHIVED_MISSION_OWNER:
      return getMyMissionBoardRoute(get(notificationEntity, 'mission.id'));
    case MISSION_INVITE_TO_CREATE_PROPOSAL:
      return getNewProposalRoute(get(notificationEntity, 'id'));
    case MESSAGE_CREATE:
      return getConversationRoute(get(notificationEntity, 'channel.id'));
    case MATCHING_MISSION:
      return getMatchingNotificationLink({ currentUser, notificationEntity });
    case MISSION_INVITATION:
      return getInvitationNotificationLink({ currentUser, notificationEntity });
    case MISSION_CANDIDATE_BETRAYAL:
      return getBookmarksRoute();
    case MISSION_ARCHIVED_CLIENT:
      return getMyMissionsRoute();
    case MISSION_ARCHIVED_EXPERT:
      return getMyApplicationsRoute();
    case RECOMMENDATION_PLATFORM_PUBLISHED:
    case MESSAGE_RECOMMENDATION_CREATE:
      return getProfileRoute();
    case PROPOSAL_ACCEPTED:
      return getContractRoute(get(notificationEntity, 'candidacy.contract.id'));
    case CONTRACT_CREATION_ADMIN_ACCEPT_EXPERT:
    case CONTRACT_CREATION_ADMIN_ACCEPT_CLIENT:
    case CONTRACT_CREATION_ADMIN_REJECT:
    case CONTRACT_CREATION_CLIENT_ACCEPT:
    case CONTRACT_CREATION_CLIENT_REJECT:
    case CONTRACT_CANCELATION_ADMIN_ACCEPT_EXPERT:
    case CONTRACT_CANCELATION_ADMIN_ACCEPT_CLIENT:
    case CONTRACT_CANCELATION_ADMIN_REJECT:
    case CONTRACT_CANCELATION_CLIENT_ACCEPT:
    case CONTRACT_CANCELATION_CLIENT_REJECT:
    case CONTRACT_MODIFICATION_ADMIN_ACCEPT_EXPERT:
    case CONTRACT_MODIFICATION_ADMIN_ACCEPT_CLIENT:
    case CONTRACT_MODIFICATION_ADMIN_REJECT:
    case CONTRACT_MODIFICATION_CLIENT_ACCEPT:
    case CONTRACT_MODIFICATION_CLIENT_REJECT:
    case CONTRACT_DELIVERY_ADMIN_ACCEPT_EXPERT:
    case CONTRACT_DELIVERY_ADMIN_ACCEPT_CLIENT:
    case CONTRACT_DELIVERY_ADMIN_REJECT:
    case CONTRACT_DELIVERY_CLIENT_ACCEPT:
    case CONTRACT_DELIVERY_CLIENT_REJECT:
      return getContractRoute(get(notificationEntity, 'id'));
    case CONTRACT_DELIVERY_IN_SEVEN_DAYS:
    case CONTRACT_DELIVERY_IN_TWO_DAYS:
    case CONTRACT_DELIVERY_TODAY:
    case CONTRACT_DELIVERY_TWO_DAYS_AGO:
    case CONTRACT_DELIVERY_X_DAYS_AGO:
      return getContractRoute(get(notificationEntity, 'contract.id'));
    case MISSION_SIGN_NDA:
      return getMyMissionNdaRoute(get(notificationEntity, 'id'));
    case PROPOSAL_REFUSED:
    case PROPOSAL_REQUEST_FOR_UPDATE:
    case PROPOSAL_REVIEW_ADMIN:
    case PROPOSAL_REVIEW_CLIENT_EXPERT:
      return getProposalRoute(proposal.get('id'));
    case PROPOSAL_REVIEW_CLIENT_CLIENT:
      return getProposalNotificationLink(proposal);
    case PROPOSAL_ARCHIVED:
      return getMyMissionProposalsRoute(proposal.get('mission.id'));
    case INVOICE_CREATED:
    case INVOICE_DELAYED:
    case INVOICE_PAYED_EXPERT:
    case INVOICE_PAYED_CLIENT:
      return getInvoicesRoute();
    case DOCUMENT_ARCHIVED:
    case DOCUMENT_CREATED:
    case DOCUMENT_FILE_REFUSED:
    case DOCUMENT_FILE_ACCEPTED:
    case DOCUMENT_FILE_EXPIRED:
    case DOCUMENT_FILE_ARCHIVED:
      return getSettingsRoute('documents');
    case CLIENTPOOL_EXPERT_ADDED:
      return getClientPoolsRoute();
    default:
      return '/';
  }
}

function Notification({
  t,
  className,
  updateNotification,
  viewed_at,
  read_at,
  current_user: currentUser,
  ...notification
}) {
  const isViewed = !isNull(viewed_at);
  const isRead = !isNull(read_at);

  const notificationId = get(notification, 'id');

  const handleClick = useCallback(
    () => {
      updateNotification({
        variables: { id: notificationId, viewed: true, read: true },
      });
    },
    [notificationId, updateNotification]
  );

  const handleToggleClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      updateNotification({
        variables: {
          id: notificationId,
          read: !isRead,
          viewed: isViewed,
        },
      });
    },
    [isRead, isViewed, notificationId, updateNotification]
  );

  const createdAt = moment(get(notification, 'created_at'));
  return (
    <Link
      to={getNotificationLink({
        text: notification.text,
        entity: notification.entity,
        currentUser,
      })}
      className={cx(
        'Notification',
        {
          'Notification--viewed': isViewed,
          'Notification--read': isRead,
        },
        className
      )}
      onClick={handleClick}
    >
      <div className="Notification__head">
        <span className="Notification__title">
          {t(`notifications.${get(notification, 'text')}.title`)}
        </span>
        <time
          dateTime={get(notification, 'created_at')}
          className="Notification__time"
        >
          {createdAt.isSame(new Date(), 'day')
            ? createdAt.format('HH:mm')
            : createdAt.format(t('DD/MM/YY'))}
        </time>
      </div>
      <Img
        {...getNotificationAvatar(notification)}
        className="Notification__avatar"
      />
      <div className="Notification__content">
        <p className="Notification__text">
          {t(...getNotificationText(notification))}
        </p>
        <button
          type="button"
          className="Notification__read-toggle"
          onClick={handleToggleClick}
        />
      </div>
    </Link>
  );
}

Notification.propTypes = {
  t: func.isRequired,
  className: string,
  updateNotification: func.isRequired,
  viewed_at: string,
  read_at: string,
};

Notification.defaultProps = {
  className: null,
  viewed_at: null,
  read_at: null,
};

export default Notification;
