import React from 'react';
import { iconsMap } from '@/constants/fields/categories';
import { get } from 'lodash';
import {
  string,
  arrayOf,
  objectOf,
  any,
  bool,
  oneOfType,
  array,
  func,
  number,
} from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { RfChoices } from '@/containers/ReduxForm';
import CategoryChoice from './CategoryChoice';
import { propType } from 'graphql-anywhere';
import categoryFragment from '@/api/Category/fragment';
import { sortTranslatedCategories as sort } from '@/helpers/sortTranslatedString';
import SectionField from '@/components/SectionField';
import { SUB_CATEGORIES } from '@/constants/fields/subCategory.js';
import './styles.scss';

function CategoriesField({
  validate,
  name,
  baseKey,
  data,
  selectedCategories,
  selectedSubcategories,
  mobile,
  required,
  subcategoriesFieldName,
  favoriteLimit,
  helper,
  labelSuffix,
}) {
  const { t } = useTranslation();
  const categories = get(data, 'categories', []);

  const fieldProps = {
    id: name,
    name,
    component: RfChoices,
    checkbox: CategoryChoice,
    cbProps: sort('fields.categories', categories, t).map((p) => ({
      ...p,
      t,
      categoryName: p.name,
      mobile,
      value: p.id,
      icon: iconsMap[p.name],
      subCategories: get(p, SUB_CATEGORIES),
      fieldName: subcategoriesFieldName,
      selectedCategories,
      selectedSubcategories,
    })),
    label: t(`${baseKey}.label${labelSuffix}`),
    inputClassName: 'grid grid--vgutter',
    validate,
    required,
    favoriteLimit,
    helper,
  };

  return <SectionField {...fieldProps} />;
}

CategoriesField.propTypes = {
  baseKey: string.isRequired,
  required: bool,
  data: arrayOf(propType(categoryFragment)).isRequired,
  selectedCategories: arrayOf(objectOf(any)).isRequired,
  selectedSubcategories: arrayOf(objectOf(any)).isRequired,
  name: string,
  validate: oneOfType([array, func]),
  favoriteLimit: number,
  helper: string,
  labelSuffix: string,
};

CategoriesField.defaultProps = {
  name: '',
  required: false,
  validate: [],
  favoriteLimit: 3,
  helper: '',
  labelSuffix: '',
};

export default CategoriesField;
