import Tooltip from '@/components/Tooltip';
import React, { useContext } from 'react';
import { string, number, bool, func, arrayOf, any } from 'prop-types';
import Tag from '@/components/Tag';
import TagsContext from '@/components/Tags/TagsContext';
import { getAssetImagePath, getFolderName } from '@/helpers/assets';
import { ReactComponent as Heart } from '@/images/heart.svg';
import { ReactComponent as HeartsSuit } from '@/images/hearts-suit.svg';
import { isTagAlreadyInFavorite } from '@/helpers/skills';
import './styles.scss';

// TODO maybe this component should be remove by using only Tag (if Tag handle tooltip)
function TagWrapper({
  id,
  name,
  withIcon,
  withFavorite,
  fieldName,
  disabledVariant,
  length,
  required,
  index,
  hasError,
  favorite,
  onChange,
  tags,
}) {
  const tagsContext = useContext(TagsContext);
  const color = tagsContext.tagColor;

  function handleVariants() {
    // if tagColor is set, it will override highlight behaviour
    if (hasError) return ['mred'];

    if (disabledVariant || (!required && color)) return [];

    if (index < tagsContext.highlightMaxValues) {
      return ['secondary'];
    }
    return [];
  }
  const TagLabel = () => (
    <span className="">
      {withIcon && (
        <img
          src={getAssetImagePath(getFolderName(fieldName), name)}
          alt="logo"
          className="TagWrapper__Tag-label--icon"
        />
      )}
      {name}
    </span>
  );

  const tagValues = () => {
    switch (true) {
      case favorite:
        return {
          tooltipTitle: `mission.favorite.tooltip.favorite.title`,
          className: 'TagWrapper__Tag--favorite-selected',
        };
      case isTagAlreadyInFavorite(tags):
        return {
          tooltipTitle: `mission.favorite.tooltip.favorite.not_allowed.title`,
          className: 'TagWrapper__Tag--favorite-not-allowed',
        };
      default:
        return {
          tooltipTitle: `mission.favorite.tooltip.no.favorite.title`,
          className: 'TagWrapper__Tag--favorite-no-selected',
        };
    }
  };

  const handleFavorite = () => {
    if (favorite || !isTagAlreadyInFavorite(tags))
      onChange(
        tags.map(
          (tag) => (tag.id === id ? { ...tag, favorite: !tag.favorite } : tag)
        )
      );
  };

  return (
    <Tag
      variants={handleVariants()}
      className={color ? `TagWrapper__Tag--${color}` : ''}
    >
      {name.length > length ? (
        <Tooltip title={name}>
          <TagLabel />
        </Tooltip>
      ) : (
        <TagLabel />
      )}
      {withFavorite && (
        <Tooltip title={tagValues().tooltipTitle}>
          <button
            type="button"
            className={`TagWrapper__Tag--favorite ${tagValues().className}`}
            onClick={() => handleFavorite()}
          >
            {favorite ? (
              <Heart className="TagWrapper__Tag--favoriteIcon" />
            ) : (
              <HeartsSuit className="TagWrapper__Tag--favoriteIcon" />
            )}
          </button>
        </Tooltip>
      )}
    </Tag>
  );
}

TagWrapper.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  length: number,
  required: bool,
  index: number,
  hasError: bool,
  withIcon: bool,
  withFavorite: bool,
  fieldName: string,
  disabledVariant: bool,
  favorite: bool,
  onChange: func.isRequired,
  tags: arrayOf(any),
};

TagWrapper.defaultProps = {
  length: 31,
  required: false,
  index: null,
  hasError: false,
  withIcon: false,
  withFavorite: false,
  fieldName: null,
  disabledVariant: false,
  favorite: false,
  tags: [],
};

export default TagWrapper;
