import { ReactComponent as Graduation } from '@/images/graduation.svg';
import { ReactComponent as Zip } from '@/images/zip.svg';
import { ReactComponent as Briefcase } from '@/images/briefcase.svg';
import { ReactComponent as File } from '@/images/file+XP.svg';

export default [
  {
    label: 'fields.fulltime_contract',
    value: 'fulltime_contract',
    id: 'fulltime_contract',
    icon: Briefcase,
    'data-testid': 'fulltime_contract',
    visible: true,
    choiceVisibility: true,
  },
  {
    label: 'fields.interim',
    value: 'interim',
    id: 'interim',
    icon: File,
    'data-testid': 'interim',
    choiceVisibility: false,
  },
  {
    label: 'fields.internship',
    value: 'internship',
    id: 'internship',
    icon: Graduation,
    'data-testid': 'internship',
    choiceVisibility: true,
  },
  {
    label: 'fields.missions',
    value: 'missions',
    id: 'missions',
    icon: Zip,
    'data-testid': 'missions',
    choiceVisibility: true,
  },
  {
    label: 'fields.missions_or_fulltime_contract',
    value: 'missions_or_fulltime_contract',
    id: 'missions_or_fulltime_contract',
    icon: File,
    'data-testid': 'missions_or_fulltime_contract',
    choiceVisibility: true,
  },
  {
    label: 'fields.professional_training_contract',
    value: 'professional_training_contract',
    id: 'professional_training_contract',
    icon: File,
    'data-testid': 'professional_training_contract',
    choiceVisibility: false,
  },
  {
    label: 'fields.temporary_contract',
    value: 'temporary_contract',
    id: 'temporary_contract',
    icon: Briefcase,
    'data-testid': 'temporary_contract',
    choiceVisibility: false,
  },
  {
    label: 'fields.thesis',
    value: 'thesis',
    id: 'thesis',
    icon: File,
    'data-testid': 'thesis',
    choiceVisibility: false,
  },
];
